import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class Page extends React.Component {



	componentDidMount() {
        // console.log(this.props.data);
        document.querySelector('#elementor-post-css').href='http://barder.loopmill.com/wp-content/uploads/elementor/css/post-' + this.props.data.wordpressPage.wordpress_id + '.css';

    }
// export default ({ data }) => {
    render() {   
        // console.log(this.props.data);

        const page = this.props.data.wordpressPage
        
        return (
            <Layout>
                <SEO title={this.props.data.wordpressPage.title} />

                <div>
                    {/* <h1>
                    {page.title} 
                    </h1> */}
                    <div dangerouslySetInnerHTML={{ __html: page.content }} />
                </div>
            </Layout>
        )

    }
}

export default Page;

export const pageQuery = graphql`
    query($slug: String!) {
        wordpressPage(slug: { eq: $slug }) {
            title
            content
            wordpress_id
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`
